import React from 'react';
import './DashboardSidebarRight.scss';
import { useSelector } from 'react-redux';
import Incentive from '../Incentive/Incentive';
import UpcomingProject from '../UpcomingProject/UpcomingProject';

function DashboardSidebarRight() {
    var incentives = useSelector(state => state.content.options.incentives);
    var manualProjects = useSelector(state => state.content.options.manual_projects);
    var whatsNew = useSelector(state => state.content.options.upcoming_projects);

    var sortedIncentives = [];
    var manualIncents = [];

    if(manualProjects) {
        for(var i = 0; i < manualProjects.length; i++) {
            for(var j = 0; j < manualProjects[i].incentives.length; j++) {
                var incent = Object.assign({}, manualProjects[i].incentives[j]);
                incent.project_name = manualProjects[i].project_name;
                incent.location = manualProjects[i].location;
                incent.project_image = manualProjects[i].project_image;
                incent.manual = true;
                sortedIncentives.push(incent);
            }
        }
    }

    if(typeof incentives !== 'undefined') {
        incentives.sort((a, b) => {
            var aDate = new Date(a.start_date);
            var bDate = new Date(b.start_date);

            return bDate > aDate;
        });

        var count = 0;
        if(incentives.length > 8) {
            count = 8;
        } else {
            count = incentives.length;
        }

        for(var i = 0; i < count; i++) {
            if(incentives[i].units.length > 0) {
                sortedIncentives.push(incentives[i]);
            }
        }
    }

    if(typeof whatsNew !== 'undefined') {
        sortedIncentives = [].concat(sortedIncentives, whatsNew);

        sortedIncentives.sort((a, b) => {
            var aDate;
            if(typeof a.start_date !== 'undefined') {
                aDate = a.start_date;
            } else {
                aDate = a.suspected_release_date;
            }

            var bDate;
            if(typeof b.start_date !== 'undefined') {
                bDate = b.start_date;
            } else {
                bDate = b.suspected_release_date;
            }

            return bDate > aDate;
        });
    }

    function returnListComponent(item, key) {   
        if(typeof item.manual !== 'undefined' ) {
            return (
                <Incentive
                    key={key}
                    points={parseInt(item.points)}
                    projectLocation={item.location}
                    projectImage={{project_image: item.project_image}}
                    projectName={item.project_name}
                    units={item.units}
                    startDate={item.start_date}
                    endDate={item.end_date}
                    date
                    manual
                />
            );
        }

        if(typeof item.suspected_release_date !== 'undefined') {
            return (
                <UpcomingProject
                    key={key}
                    image={item.image}
                    heading={item.title}
                    register={item.register_link}
                />
            );
        }

        return (
            <Incentive
                key={key}
                project={item.project.id}
                points={item.points}
                units={item.units}
                projectName={item.project.name}
                startDate={item.start_date}
                endDate={item.end_date}
                date
            />
        );
    }

    return (
        <div className="DashboardSidebarRight">
            <h2>What's New</h2>
            <div className="dashboard-content-container">
                {sortedIncentives.map((item, key) => 
                    returnListComponent(item, key)
                )}
            </div>
        </div>
    );
}

export default DashboardSidebarRight;
