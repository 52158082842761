import "./Router.scss";
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import Home from '../Home/Home';
import CreateAccount from '../CreateAccount/CreateAccount';
import AccountCreated from '../AccountCreated/AccountCreated';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import CreateNewPassword from '../CreateNewPassword/CreateNewPassword';
import Dashboard from '../Dashboard/Dashboard';
import Portfolio from '../Portfolio/Portfolio';
import Incentives from '../Incentives/Incentives';
import Notifications from '../Notifications/Notifications';
import BuilderStory from '../BuilderStory/BuilderStory';
import TierDetails from '../TierDetails/TierDetails';
import FAQ from '../FAQ/FAQ';
import TermsConditions from '../TermsConditions/TermsConditions';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import UserLoggedIn from '../UserLoggedIn/UserLoggedIn';
import Profile from '../Profile/Profile';
import AccountVerified from '../AccountVerified/AccountVerified';
import WhatsNew from "../WhatsNew/WhatsNew";
import DashboardPointsSummary from '../DashboardPointsSummary/DashboardPointsSummary';
import PopupMessages from '../PopupMessages/PopupMessages';
import ProjectDetails from '../ProjectDetails/ProjectDetails';
import MobileBottomBar from '../MobileBottomBar/MobileBottomBar';
import DashboardNotificationBar from '../DashboardNotificationBar/DashboardNotificationBar';
import MobileTopBar from "../MobileTopBar/MobileTopBar";
import { useDispatch } from "react-redux";
import { addPopupMessage } from "../../redux/actions/popupActions";

function Router() {
    var loggedIn = useSelector(state => state.user.userLoggedIn);
    

    var styles = {};
    if(!loggedIn) {
        styles.paddingBottom = "0";
    }

    var [menuOpen, setMenuOpen] = useState(false);


    return (
        <BrowserRouter>
            <PopupMessages />
            <MobileTopBar loggedIn={loggedIn} open={menuOpen} setOpen={setMenuOpen} />
            <div className="router-content-wrapper" style={styles}>
                <Switch>
                    <Route path="/" exact>
                        <Home />
                    </Route>
                    <Route path="/create-account">
                        <CreateAccount />
                    </Route>
                    <Route path="/account-created">
                        <AccountCreated />
                    </Route>
                    <Route path="/account-verified">
                        <AccountVerified />
                    </Route>
                    <Route path="/forgot-password">
                        <ForgotPassword />
                    </Route>
                    <Route path="/create-new-password">
                        <CreateNewPassword />
                    </Route>
                    <Route path="/dashboard" exact>
                        <UserLoggedIn />
                        <Dashboard />
                    </Route>
                    <Route path="/dashboard/whats-new">
                        <UserLoggedIn />
                        <WhatsNew />
                    </Route>
                    <Route path="/dashboard/summary">
                        <UserLoggedIn />
                        <DashboardPointsSummary />
                    </Route>
                    <Route path="/portfolio" exact>
                        <UserLoggedIn />
                        <Portfolio />
                    </Route>
                    <Route path="/portfolio/project">
                        <UserLoggedIn />
                        <ProjectDetails />
                    </Route>
                    <Route path="/incentives">
                        <UserLoggedIn />
                        <Incentives />
                    </Route>
                    <Route path="/notifications/:id">
                        <UserLoggedIn />
                        <Notifications />
                    </Route> 
                    <Route path="/notifications">
                        <UserLoggedIn />
                        <Notifications />
                    </Route>
                    <Route path="/builder-story">
                        <UserLoggedIn />
                        <BuilderStory />
                    </Route>
                    <Route path="/tier-details">
                        <UserLoggedIn />
                        <TierDetails />
                    </Route>
                    <Route path="/faq">
                        <UserLoggedIn />
                        <FAQ />
                    </Route>
                    <Route path="/terms-and-conditions">
                        <TermsConditions />
                    </Route>
                    <Route path="/privacy-policy">
                        <PrivacyPolicy />
                    </Route>
                    <Route path="/profile">
                        <UserLoggedIn />
                        <Profile />
                    </Route>
                    <Route path="/profile/details">
                        <UserLoggedIn />
                        <Profile page="details" />
                    </Route>
                    <Route path="/profile/security">
                        <UserLoggedIn />
                        <Profile page="security" />
                    </Route>
                    <Route path="/profile/preferences">
                        <UserLoggedIn />
                        <Profile page="preferences" />
                    </Route>
                </Switch>
            </div>
            <MobileBottomBar loggedIn={loggedIn} open={menuOpen} setOpen={setMenuOpen} />
        </BrowserRouter>
    );
}

export default Router;