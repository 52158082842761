import "./MobileTopBar.scss";
import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router";
import { Link } from 'react-router-dom';
import MobileHamburger from "../MobileHamburger/MobileHamburger";
import { useSelector } from "react-redux";

function MobileTopBar(props) {
    var [width, setWidth] = useState(window.innerWidth);
    var location = useLocation();

    var verified = useSelector(state => state.user.userVerified);
    var confirmed = useSelector(state => state.user.userConfirmed);

    var classes = "MobileTopBar";

    function updateSize() {
        setWidth(window.innerWidth);
    }

    if(location.pathname === "/") classes += " hidden";

    useEffect(() => {
        window.addEventListener('resize', updateSize);

        return () => {
            window.removeEventListener('resize', updateSize);
        }
    });

    return (
        <div className={classes}>
            <Link className="mobile-ascent-logo" to="/">
                Ascent<br/>Program
            </Link>
            {props.loggedIn && verified && confirmed &&
                <React.Fragment>
                    <Link className="whats-new" to="/dashboard/whats-new">
                        What's New
                    </Link>    
                    <div className="hamburger-wrapper">
                        <MobileHamburger open={props.open} setOpen={props.setOpen} />
                    </div>
                </React.Fragment>
            }
        </div>
    )
}

export default MobileTopBar;